<template>
    <div>
        
        <CRow>
            <CCol lg="12">
                <CButton
                    @click="$router.go(-1)"
                    size="sm"
                    color="warning" 
                > 
                    <font-awesome-icon icon="arrow-left"/> Go back
                </CButton>
            </CCol>
        </CRow>
        <br>
        <CRow>
            <CCol lg="12">
                <CCard :key="update_key">
                    <CCardHeader><font-awesome-icon icon="cog"/>  Manage Document Template Prompt
                        <template v-if="template_prompt.status == 'Active'">
                            <CButton 
                                size="sm"
                                color="danger" 
                                class="float-right" 
                                @click="toggleEdit(), returnInformation()"
                                v-show="edit"
                            >
                                <font-awesome-icon icon="window-close"/> Cancel
                            </CButton>
                            <CButton 
                                v-if="$store.getters['can']('update-document-template-prompt')"
                                size="sm"
                                :disabled="!isValid"
                                color="success" 
                                class="float-right" 
                                v-show="edit" 
                                @click="updatePrompt()"
                            >
                                <font-awesome-icon icon="save"/> Update
                            </CButton>
                            <CButton 
                                v-if="$store.getters['can']('edit-document-template-prompt')"
                                size="sm"
                                color="info" 
                                class="float-right" 
                                @click="toggleEdit" v-show="!edit"
                            >
                                <font-awesome-icon icon="edit"/> Edit
                            </CButton>
                        </template>
                        <template v-if="template_prompt.status == 'Archived'">
                            <CButton 
                                v-if="$store.getters['can']('restore-document-template-prompt')"
                                size="sm"
                                color="success" 
                                class="float-right" 
                                @click="restore()"
                            >
                                <font-awesome-icon icon="trash-restore"/> Restore
                            </CButton>
                        </template>
                    </CCardHeader>
                    <CCardBody>
                        <CRow>
                            <CCol lg="6">
                                <CInput :lazy="false" label="Name" type="text"
                                    :disabled="!edit"
                                    autocomplete="off" v-tocapitalize v-model="template_prompt.name"
                                    invalidFeedback="Must be three(3) characters." 
                                    :value.sync="$v.template_prompt.name.$model"
                                    :isValid="checkIfValid($v.template_prompt, 'name')"
                                    />
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol lg="12" md="12" xl="12">
                                <CTextarea class="grp-text-area" :lazy="false"
                                    :disabled="!edit"
                                    label="Description" autocomplete="off" v-tocapitalize 
                                    v-model="template_prompt.description"
                                    :value.sync="$v.template_prompt.description.$model"
                                    :isValid="checkIfValid($v.template_prompt, 'description')"
                                    />
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol lg="12" md="12" xl="12">
                                <CTextarea
                                    :lazy="false"
                                    rows="5"
                                    :disabled="!edit"
                                    label="Prompt" autocomplete="off" v-tocapitalize
                                    v-model="template_prompt.prompt"
                                    :value.sync="$v.template_prompt.prompt.$model"
                                    :isValid="checkIfValid($v.template_prompt, 'prompt')"
                                    />
                            </CCol>
                        </CRow>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
        
        <CRow v-show="edit">
            <CCol lg="8">
                <CCard borderColor="danger" v-if="$store.getters['can']('archive-document-template-prompt')">
                    <CCardHeader>
                        <font-awesome-icon icon="exclamation" :style="{ color: 'red' }"/> 
                                Danger Zone
                    </CCardHeader>
                    <CCardBody>
                        <CRow>
                            <CCol lg="8">
                                <h6>Archive this Document Template Prompt ?</h6>
                            </CCol>
                            <CCol lg="12">
                                <label> Once archived, you cannot use this prompt anymore. Please be certain.</label>
                            </CCol>
                        </CRow>
                        <br>
                        <CRow>
                            <CCol lg="3">
                                <CButton shape="pill" color="warning" @click="archive()" > 
                                    <font-awesome-icon icon="minus-square" /> Archived 
                                </CButton>
                            </CCol>
                        </CRow>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>

<script>
import { required, minLength} from "vuelidate/lib/validators"
export default {
    name:'DocumentTemplateEdit',
    data(){
        return {
            template_prompt: {
                name: '',
                description: '',
                prompt: ''
            },
            update_key: 0,
            template_prompt_backup: {},
            disable: 1,
            edit: false,
        }
    },
    computed: {
        isValid () { return !this.$v.template_prompt.$invalid },
        isDirty () { return this.$v.template_prompt.$anyDirty },
    },
    created() {
        this.getPrompt();
    },
    validations: { 
        template_prompt: {
            name: { required, minLength: minLength(3) },
            description: { required },
            prompt: { required },

        },
    },
    methods: {
        archive: function () { 
            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                text: `You are trying to archive the ${this.template_prompt.name}`,
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                preConfirm: () => { 
                    this.$Progress.start()
                    return axios.post('drs/document-template-prompt/archive/' + this.$route.params.id, {validateStatus: () => true})
                    .then(response => {
                        if(response.status == 200) {
                            this.update_key++;
                            this.template_prompt_backup.status = 'Archived'
                            this.returnInformation()
                            this.toggleEdit()
                            this.$Progress.finish()
                            this.$swal({
                                toast: true,
                                position: 'top-right',
                                timer: 3000,
                                icon: 'success',
                                title: `${this.template_prompt.name} successfully archived.`,
                                showConfirmButton: false,
                                timerProgressBar: true,
                            })
                        }
                    })
                }
            }).then((result) => {
                if(!result.value){
                    this.$swal('Cancelled!','','error');
                }
            })
        },
        restore: function() {
            this.$Progress.start()
            axios.post('drs/document-template-prompt/restore/' + this.$route.params.id, {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    this.update_key++;
                    this.template_prompt_backup.status = 'Active'
                    this.returnInformation()
                    // this.toggleEdit()
                    this.$Progress.finish() 

                    this.$swal({
                        toast: true,
                        position: 'top-right',
                        timer: 3000,
                        icon: 'success',
                        title: `${this.template_prompt.name} restored successfully.`,
                        showConfirmButton: false,
                        timerProgressBar: true,
                    })
                }
            })
        },
        getPrompt: function () {
            axios.get('drs/document-template-prompt/' + this.$route.params.id, {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    this.template_prompt.name = response.data.data.name
                    this.template_prompt.description = response.data.data.description
                    this.template_prompt.prompt = response.data.data.prompt
                    this.template_prompt.status = response.data.data.deleted_at ? "Archived" : "Active";
                    this.template_prompt_backup = Vue.util.extend({}, this.template_prompt)
                }
            })
        },
        returnInformation: function () {
            this.template_prompt = Vue.util.extend({}, this.template_prompt_backup)
        },
        updatePrompt: function() { 
            if(JSON.stringify(this.template_prompt) == JSON.stringify(this.template_prompt_backup)) {
               this.$swal({
                    icon: "info",
                    title: "Opps!",
                    text: "No changes has been made.",
                })
                return;
            }
            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                text: `You are trying to update the ${this.template_prompt.name}`,
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                preConfirm: () => { 
                    this.$Progress.start()
                    return axios.post('drs/document-template-prompt/update/' + this.$route.params.id, this.template_prompt, {validateStatus: () => true})
                    .then(response => {
                        if(response.status == 200) {
                            this.$swal({
                                toast: true,
                                position: 'top-right',
                                timer: 3000,
                                icon: 'success',
                                title: `${this.template_prompt.name} successfully updated.`,
                                showConfirmButton: false,
                                timerProgressBar: true,
                            })
                            this.update_key++;
                            
                            this.template_prompt.name = response.data.data.name
                            this.template_prompt.status = response.data.data.deleted_at ? "Archived" : "Active";
                            this.template_prompt_backup = Vue.util.extend({}, this.template_prompt)

                            this.returnInformation()
                            this.toggleEdit()
                            this.$Progress.finish()
                        }
                    })
                }
            }).then((result) => {
                if(!result.value){
                    this.$swal('Cancelled!','','error');
                }
            })
        },
        toggleEdit: function() {
            this.edit = !this.edit
            this.disable = (this.disable + 1) % 2
            if(!this.disable) { 
                this.$v.template_prompt.$touch()
            }
            else {
                this.$v.template_prompt.$reset()
            }
        },
    }
}
</script>

<style>

</style>